<template>
  <el-dialog
    width="600px"
    :title="$t('common.account')"
    :visible.sync="dialogFormVisible"
    :before-close="handleDialogClose"
  >
    <el-form
      label-position="top"
      label-width="108px"
      :model="changeForm"
      :rules="changePasswordRules"
      ref="changeFormRef"
    >
      <el-form-item
        prop="current_password"
        :label="$t('common.old_password')"
      >
        <el-input
          v-model="changeForm.current_password"
          autocomplete="off"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="password"
        :label="$t('common.password')"
      >
        <el-input
          v-model="changeForm.password"
          autocomplete="off"
          type="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="confirm_password"
        :label="$t('common.repeat_password')"
      >
        <el-input
          v-model="changeForm.confirm_password"
          autocomplete="off"
          type="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item class="mb-0">
        <el-button
          style="margin-right: 10px;"
          type="text"
          @click="createRandomPassword()"
        >{{ $t('common.random_password') }}</el-button>
        <code v-if="randomPassword">{{ randomPassword }}</code>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{ $t('common.cancel') }}</el-button>
      <el-button
        @click="submitChangePassword"
        type="primary"
      >{{ $t('common.reset_password') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { randomPassword } from '@/utils/util';

export default {
  name: 'ChangePasswordDialog',
  data() {
    const oldPwValidator = (rule, value, cb) => {
      if (value === '') {
        cb(new Error(this.$t('rules.password_placeholder')));
      } else {
        cb();
      }
    };
    const pwValidator = (rule, value, cb) => {
      const ptn = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (value === '') {
        cb(new Error(this.$t('rules.password_placeholder')));
      } else if (!ptn.test(value)) {
        cb(new Error(this.$t('rules.password_format')));
      } else {
        cb();
      }
    };
    const pwConfirmValidator = (rule, value, cb) => {
      if (value === '') {
        cb(new Error(this.$t('rules.password_placeholder')));
      } else if (value !== this.changeForm.password) {
        cb(new Error(this.$t('rules.password_repeat')));
      } else {
        cb();
      }
    };

    return {
      dialogFormVisible: false,
      randomPassword: '',
      changeForm: {
        app_key: process.env.VUE_APP_APP_KEY,
        current_password: '',
        password: '',
        confirm_password: '',
      },
      changePasswordRules: {
        current_password: [
          { required: true, validator: oldPwValidator },
        ],
        password: [
          { required: true, validator: pwValidator },
        ],
        confirm_password: [
          { required: true, validator: pwConfirmValidator },
        ],
      },
    };
  },
  methods: {
    goToChangePassword() {
      this.dialogFormVisible = true;
    },
    createRandomPassword() {
      this.randomPassword = randomPassword();
      this.changeForm.password = this.randomPassword;
      this.changeForm.confirm_password = this.randomPassword;
    },
    clearPassword() {
      this.randomPassword = '';
    },
    submitChangePassword() {
      this.$refs.changeFormRef.validate((valid) => {
        if (valid) {
          this.$api.changePassword(this.changeForm)
            .then(() => {
              this.$message.success(this.$t('common.change_success'));
              this.resetForm();
              this.dialogFormVisible = false;
            })
            .catch((res) => {
              const { reason } = res.data;
              let errMsg = this.$t('common.change_fail');

              if (reason === 'incorrect current password') {
                errMsg = this.$t('common.original_password_error');
              }

              this.$message.error(errMsg);
              console.log('changePassword failed', res);
            });
        }
      });
    },
    handleDialogClose(done) {
      this.resetForm();
      done();
    },
    resetForm() {
      this.$refs.changeFormRef.clearValidate();
      this.$refs.changeFormRef.resetFields();
    },
  },
};
</script>

/* eslint-disable camelcase */
/* eslint-disable max-len  */
import request from './utils';

// const authUrls = {

// };

const urlPrefix = '/api/common';
const urls = {
  // users
  readMe: '/users/me',
  readUser: '/users/<id>',
  readUsers: '/users',
  createUsers: '/users',
  updateUsers: '/users/<id>',
  deleteUsers: '/users/<id>',
  // schedule manage
  scheduleCheck: '/schedule/check',
  schedulePatch: '/schedule/patch_tasks',
  scheduleReplace: '/schedule/replace',
  scheduleMute: '/schedule/mute',
  scheduleSetRepair: '/schedule/set_repair',
  schedulePublishStatus: '/schedule/publish_status',
  // about token and automation
  scheduleRestScheduleToken: '/schedule/reset_schedule_token',
  scheduleWGSNDag: '/schedule/wgsn_call_dag',
  schedulePushTasks: '/schedule/push_tasks',

  // requirement
  requirementGetSyncRequirementJobList: '/requirement/get_sync_requirement_job_list',
  requirementSyncRequirement: '/requirement/sync_requirement',
  requirementMasterlistPathValid: '/requirement/masterlist_path_valid',
  requirementToolsGenFeedId: '/requirement/tools/gen_feed_id',
  requirementToolsGenStandardReq: '/requirement/tools/gen_standard_req',
  requirementGetSyncRequirementExecutingJob: '/requirement/get_sync_requirement_executing_job',
  requirementGetDevTracking: '/requirement/dev_tracking',
  requirementGetDevDeliOverview: '/requirement/dev_delivery_overview',
  requirementGetRequirements: '/requirement/requirements',
  // rundeck
  rundeckGetExecutionState: '/requirement/rundeck/get_execution_state',
  // common
  commonGetBasicInfo: '/common/basic_info',
  // delivery
  deliveryGetUnDeliver: '/delivery/weekly_un_deliver',
  deliveryIgnoreRecord: '/delivery/ignore_record',
  deliveryGetDutyReport: '/delivery/get_duty_report',
  deliverySendDutyReport: '/delivery/send_duty_report',
  deliveryGetRepairData: '/delivery/get_repair_data',
  // masterlist
  masterlistUpdate: '/masterlist/update',
  masterlistUpdating: '/masterlist/updating',
  masterlistSearchMeta: '/masterlist/search_meta',
  masterlistETLUpdate: '/masterlist/submit_ETL_gen_ML_data_task',
  masterlistETLUpdating: '/masterlist/get_ETL_gen_ML_data_status',

  // test
  testGenManifest: '/test/submit_manifest_task',
  testGetManifestStatus: '/test/get_manifest_task_status',
  testGenOneTestCases: '/test/run_onetest_cases',
  testGetOneTestCasesStatus: '/test/get_onetest_task_status',
};

Object.keys(urls).map((url) => {
  urls[url] = urlPrefix + urls[url];
  return url;
});

export default new function API() {
  // users
  this.readMe = () => request.get(urls.readMe);
  this.readUser = (id) => request.get(urls.readUser.replace('<id>', id));
  this.readUsers = () => request.get(urls.readUsers, {});
  this.createUsers = (params) => request.post(urls.createUsers, params);
  this.updateUsers = (id, params) => request.put(urls.updateUsers.replace('<id>', id), params);
  this.deleteUsers = (id) => request.delete(urls.deleteUsers.replace('<id>', id));
  // schedule manage
  this.scheduleCheck = (params) => request.get(urls.scheduleCheck, params);
  this.schedulePatch = (params) => request.get(urls.schedulePatch, params);
  this.scheduleReplace = (params) => request.get(urls.scheduleReplace, params);
  this.scheduleMute = (params) => request.get(urls.scheduleMute, params);
  this.scheduleSetRepair = (params) => request.post(urls.scheduleSetRepair, params);
  this.scheduleRestScheduleToken = (params) => request.get(urls.scheduleRestScheduleToken, params);
  this.scheduleWGSNDag = (params) => request.get(urls.scheduleWGSNDag, params);
  // requirement
  this.requirementGetSyncRequirementJobList = (params) => request.get(urls.requirementGetSyncRequirementJobList, params);
  this.requirementSyncRequirement = (params) => request.get(urls.requirementSyncRequirement, params);
  this.requirementMasterlistPathValid = (params) => request.get(urls.requirementMasterlistPathValid, params);
  this.requirementToolsGenFeedId = (params) => request.get(urls.requirementToolsGenFeedId, params);
  this.requirementToolsGenStandardReq = (params) => request.post(urls.requirementToolsGenStandardReq, params);
  this.requirementGetSyncRequirementExecutingJob = () => request.get(urls.requirementGetSyncRequirementExecutingJob);
  this.requirementGetDevTracking = () => request.get(urls.requirementGetDevTracking);
  this.requirementGetRequirements = (params) => request.get(urls.requirementGetRequirements, params);
  this.requirementGetDevDeliOverview = () => request.get(urls.requirementGetDevDeliOverview);
  // rundeck
  this.rundeckGetExecutionState = (params) => request.get(urls.rundeckGetExecutionState, params);
  // common
  this.commonGetBasicInfo = () => request.get(urls.commonGetBasicInfo);
  // delivery
  this.deliveryGetUnDeliver = (params) => request.get(urls.deliveryGetUnDeliver, params);
  this.deliveryGetDutyReport = (params) => request.get(urls.deliveryGetDutyReport, params);
  this.deliverySendDutyReport = (params) => request.post(urls.deliverySendDutyReport, params);
  this.deliveryGetRepairData = () => request.get(urls.deliveryGetRepairData);
  this.deliveryIgnoreRecord = (params) => request.post(urls.deliveryIgnoreRecord, params);
  // masterlist
  this.masterlistUpdate = (params) => request.get(urls.masterlistUpdate, params);
  this.masterlistUpdating = () => request.get(urls.masterlistUpdating);
  this.masterlistSearchMeta = (params) => request.get(urls.masterlistSearchMeta, params);
  this.masterlistETLUpdate = (params) => request.get(urls.masterlistETLUpdate, params);
  this.masterlistETLUpdating = () => request.get(urls.masterlistETLUpdating);
  // test
  this.testGenManifest = (params) => request.get(urls.testGenManifest, params);
  this.testGetManifestStatus = () => request.get(urls.testGetManifestStatus);

  this.testGenOneTestCases = (params) => request.get(urls.testGenOneTestCases, params);
  this.testGetOneTestCasesStatus = () => request.get(urls.testGetOneTestCasesStatus);
}();

// constants
export const INCORRECT_CURRENT_PASSWORD = 'incorrect current password';
export const INVALID_LOGIN_OR_PASSWORD = 'invalid login or password';
export const NAME_ALREADY_EXISTS = 'name already exists';
export const USERNAME_ALREADY_EXISTS = 'username already exists';
export const EMAIL_ALREADY_EXISTS = 'email already exists';
export const INVALID_ARGUMENT = 'invalid argument';
export const NOT_FOUND = 'not found';
export const PROJECT_ALREADY_EXISTS = 'the project already exists';
export const PROJECT_NOT_EXISTS = 'the project does not exist';
export const PROJECT_MAP = {
  "Digital Shelf": "ascential_digital_shelf",
  "Dyson": "ascential_dyson",
  "L'oreal": "ascential_loreal",
  "Series": "ascential_series",
}

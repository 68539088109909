<template>
<!--  <el-main :style="{'background-image': is_sx(), 'background-size': 'cover', 'background-repeat': 'repeat'}">-->
  <el-main>
    <transition
      name="move"
      mode="out-in"
    >
<!--      <div style="height: 100%; width: 100%;" class="note" :style ="note">-->
      <router-view />
<!--      </div>-->
    </transition>
  </el-main>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
      custom_other: null,
      custom_bg: null,
      note: {
        backgroundImage: "url(" + require("@/assets/bg-container-new.jpeg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // marginTop: "5px",
      },
    }
  },
  methods: {
    is_sx() {
      if (this.user.username === 'liangzhichong'){
        return this.custom_bg
      }
      return this.custom_other
    }
  },
  computed: {
    user() {
      return this.$auth.token() && this.$store.state.auth ? this.$store.state.auth.user || {} : {};
    },
  },
};
</script>

import Axios from 'axios';
import path from 'path';

export default class AxiosService {
  constructor({
    appKey,
    hamletPrefix = '/api/auth',
    authType = 'Bearer',
    authFailCallback = (response) => response,
  }) {
    this.appKey = appKey;
    this.hamletPrefix = hamletPrefix;
    this.authType = authType;
    this.authFailCallback = authFailCallback;

    this.refreshTokenTimer = null;
    this.refreshTokenTimerIntervalMS = 10 * 1000; // 定时器每10秒检查一次是否要刷新令牌
    this.refreshTokenIntervalMS = 10 * 60 * 1000; // 10分钟刷新一次令牌

    this.http = Axios.create();
    this.http.interceptors.request.use((request) => {
      if (request.url.includes(this.hamletPrefix)) {
        if (['GET', 'OPTIONS', 'HEAD'].includes(request.method.toUpperCase())) {
          request.params.app_key = this.appKey;
        } else {
          request.data.app_key = this.appKey;
        }
      }

      const token = localStorage.getItem(this.accessTokenKey);
      if (token) {
        request.headers.Authorization = `${this.authType} ${token}`;
      }
      return request;
    });
    this.http.interceptors.response.use((response) => {
      if (response.status === 401) {
        this.logout();
        return this.authFailCallback(response);
      }
      return response;
    });

    if (localStorage.getItem(this.accessTokenKey) && localStorage.getItem(this.refreshTokenKey)) {
      clearInterval(this.refreshTokenTimer);
      this.refreshTokenTimer = setInterval(() => this.refresh(), this.refreshTokenTimerIntervalMS);
    }
  }

  login({ username, password }) {
    const url = path.join(this.hamletPrefix, 'login');
    const params = { __randNum: Math.random() };
    const data = {
      app_key: this.appKey,
      username,
      password,
    };
    return this.http.post(url, data, { params }).then((response) => {
      const res = response.data;
      if (!res.ok) {
        return Promise.reject(response);
      }

      localStorage.setItem(this.accessTokenKey, res.data.access_token);
      localStorage.setItem(this.refreshTokenKey, res.data.refresh_token);
      localStorage.setItem(this.refreshTSKey, new Date().getTime().toString());

      clearInterval(this.refreshTokenTimer);
      this.refreshTokenTimer = setInterval(() => this.refresh(), this.refreshTokenTimerIntervalMS);
      return response;
    });
  }

  logout() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.refreshTSKey);
    clearInterval(this.refreshTokenTimer);
  }

  refresh() {
    if (!this.authorized) {
      return;
    }

    let refreshTS = localStorage.getItem(this.refreshTSKey);
    if (refreshTS !== null) {
      refreshTS = parseInt(refreshTS, 10);
      const currentTS = new Date().getTime();
      if (currentTS - refreshTS < this.refreshTokenIntervalMS) {
        return;
      }
    }

    const url = path.join(this.hamletPrefix, 'refresh');
    const params = {
      refresh_token: localStorage.getItem(this.refreshTokenKey),
      __randNum: Math.random(),
    };
    this.http.get(url, { params }).then((response) => {
      const res = response.data;
      if (!res.ok) {
        console.warn(`刷新令牌失败: ${res.reason}`);
        return;
      }

      localStorage.setItem(this.accessTokenKey, res.data.access_token);
      localStorage.setItem(this.refreshTSKey, new Date().getTime().toString());
    });
  }

  get authorized() {
    return localStorage.getItem(this.accessTokenKey) && localStorage.getItem(this.refreshTokenKey);
  }

  get accessTokenKey() {
    return `${this.appKey}:access_token`;
  }

  get refreshTokenKey() {
    return `${this.appKey}:refresh_token`;
  }

  get refreshTSKey() {
    return `${this.appKey}:refresh_ts`;
  }
}

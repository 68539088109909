import Vue from 'vue';
import Vuex from 'vuex';
import users from './modules/users';
import common from './modules/common';
import projects from './modules/projects';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  modules: {
    common,
    users,
    projects,
  },
  strict: debug,
});

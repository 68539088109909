import Vue from 'vue';
import Router from 'vue-router';
import VueRouter from 'vue-router';
import Login from '@/views/auth/TheLogin.vue';
import AppLayout from '@/views/layouts/AppLayout.vue';

Vue.use(Router);

const Create = {
  template: '<div class="title">Create</div>',
};

const Read = {
  template: '<div class="title">Read</div>',
};

const Update = {
  template: '<div class="title">Update</div>',
};

const Delete = {
  template: '<div class="title">Delete</div>',
};

/**
* TIPS:
* meta: {
*   hidden: false,    // If `hidden:true` will not appear in the navigation bar or sidebar(default is false)
*   auth: [],         // It will control the page roles (you can set multiple roles)
*   icon: 'home',     // Icon will appear in the navigation bar or sidebar
*   hasMulSub: false, // It has multiple children
* }
*/
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        hidden: true,
      },
    },
    {
      path: '/',
      redirect: { name: 'Index' },
      meta: {
        hidden: true,
      },
    },
    {
      path: '/index',
      component: AppLayout,
      redirect: { name: 'Index' },
      children: [
        {
          path: '/index',
          name: 'Index',
          component: () => import('@/views/common/VHomepage.vue'),
          meta: {
            icon: 'home',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
    {
      path: '/users',
      component: AppLayout,
      redirect: { name: 'Users' },
      children: [
        {
          path: 'index',
          name: 'Users',
          component: () => import('@/views/admin/TheUsers.vue'),
          meta: {
            icon: 'people_outline',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
    {
      path: '/actions',
      name: 'Actions',
      component: AppLayout,
      redirect: { name: 'Create' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/create',
          name: 'Create',
          component: Create,
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/read',
          name: 'Read',
          component: Read,
          meta: {
            icon: 'insert_drive_file',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/update',
          name: 'Update',
          component: Update,
          meta: {
            icon: 'update',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delete',
          name: 'Delete',
          component: Delete,
          meta: {
            icon: 'delete',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
    {
      path: '/403',
      meta: {
        hidden: true,
      },
      // redirect: { name: 'Index' },
      component: Login,
    },
    {
      path: '*',
      meta: {
        hidden: true,
      },
      redirect: { name: 'Index' },
    },

    {
      path: '/tasks_manage',
      name: 'TasksManage',
      component: AppLayout,
      redirect: { name: 'Publish' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/tasks_manage/publish',
          name: 'Publish',
          component: () => import('@/views/admin/schedule/ThePublish.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/schedule',
          name: 'Schedule',
          component: () => import('@/views/admin/schedule/TheSchedule.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/handle',
          name: 'Handle',
          component: () => import('@/views/admin/schedule/TheHandle.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/tasks_manage/test',
          name: 'Test',
          component: () => import('@/views/admin/schedule/TheTest.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        }
      ],
    },

    {
      path: '/requirements_manage',
      name: 'RequirementsManage',
      component: AppLayout,
      redirect: { name: 'Requirements' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/requirements_manage/dev_delivery_overview',
          name: 'Overview',
          component: () => import('@/views/admin/requirement/TheOverview.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/dev_tracking',
          name: 'Tracking',
          component: () => import('@/views/admin/requirement/TheDevTracking.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/requirements',
          name: 'Requirements',
          component: () => import('@/views/admin/requirement/TheRequirements.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/sync',
          name: 'Sync',
          component: () => import('@/views/admin/requirement/TheSyncRequirement.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/tools',
          name: 'Tools',
          component: () => import('@/views/admin/requirement/TheTools.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/masterlist',
          name: 'Masterlist',
          component: () => import('@/views/admin/requirement/TheMasterlist.vue'),
          meta: {
            icon: 'insert_drive_file',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/requirements_manage/search',
          name: 'Search',
          component: () => import('@/views/admin/requirement/TheSearch.vue'),
          meta: {
            icon: 'update',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },

    {
      path: '/delivery_manage',
      name: 'DeliveryManage',
      component: AppLayout,
      redirect: { name: 'DeliveryStatus' },
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/delivery_manage/delivery_status',
          name: 'DeliveryStatus',
          component: () => import('@/views/admin/delivery/TheDelivery.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/auto_commit',
          name: 'AutoCommit',
          component: () => import('@/views/admin/delivery/TheAutoCommit.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/repair',
          name: 'Repair',
          component: () => import('@/views/admin/delivery/TheRepair.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/delivery_manage/duty',
          name: 'DutyReport',
          component: () => import('@/views/admin/delivery/TheDutyReport.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },

      ],
    },

    // 2024-06-07 感觉wiki里的内容都相对过时了，没人维护，而且容易分裂飞书wiki。先下线吧。
    // {
    //   path: '/wiki',
    //   name: 'Wiki',
    //   component: AppLayout,
    //   redirect: { name: 'ShowWiki' },
    //   meta: {
    //     hasMulSub: true,
    //   },
    //   children: [
    //     {
    //       path: '/wiki/wiki',
    //       name: 'ShowWiki',
    //       component: () => import('@/views/admin/wiki/TheWiki.vue'),
    //       meta: {
    //         icon: 'add',
    //         auth: ['root', 'admin', 'general', ''],
    //       },
    //     },
    //   ],
    // },

    {
      path: '/response',
      name: 'Response',
      component: AppLayout,
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/response/group',
          name: 'ResponseGroup',
          component: () => import('@/views/admin/response/TheGroup.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/response/query',
          name: 'ResponseQuery',
          component: () => import('@/views/admin/response/TheQuery.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
        {
          path: '/response/result',
          name: 'ResponseResult',
          component: () => import('@/views/admin/response/TheResult.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },

    {
      path: '/spider',
      name: 'Spider',
      component: AppLayout,
      meta: {
        hasMulSub: true,
      },
      children: [
        {
          path: '/spider/mgmt',
          name: 'SpiderMgmt',
          component: () => import('@/views/admin/spider/TheMgmt.vue'),
          meta: {
            icon: 'add',
            auth: ['root', 'admin', 'general', ''],
          },
        },
      ],
    },
  ],
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import * as types from './types';

const state = {
  trackingData: [],
  rdSyncReqJobList: [],
  basicInfo: {},
  deliveryData: [],
  repairData: [],
  setRepairData: [],
  devDeliOverviewData: [],
}

const getters = {};

const actions = {
  updateTracking({ commit }, value) {
    commit(types.UPDATE_TRACKING, { value });
  },
  updateRdSyncReqJob({ commit }, value) {
    commit(types.UPDATE_RD_SYNC_REQ_JOB, { value });
  },
  updateBasicInfo({ commit }, value) {
    commit(types.UPDATE_BASIC_INFO, { value });
  },
  updateDeliveryData({ commit }, value) {
    commit(types.UPDATE_DELIVERY_DATA, { value });
  },
  updateRepairData({ commit }, value) {
    commit(types.UPDATE_REPAIR_DATA, { value });
  },
  updateSetRepairData({ commit }, value) {
    commit(types.UPDATE_SET_REPAIR_DATA, { value });
  },
  updateDevDeliOverviewData({ commit }, value) {
    commit(types.UPDATE_DEV_DELI_OVERVIEW_DATA, { value });
  },
}

const mutations = {
  [types.UPDATE_TRACKING](state, { value }) {
    state.trackingData = value;
  },
  [types.UPDATE_RD_SYNC_REQ_JOB](state, { value }) {
    state.rdSyncReqJobList = value;
  },
  [types.UPDATE_BASIC_INFO](state, { value }) {
    state.basicInfo = value;
  },
  [types.UPDATE_DELIVERY_DATA](state, { value }) {
    state.deliveryData = value;
  },
  [types.UPDATE_REPAIR_DATA](state, { value }) {
    state.repairData = value;
  },
  [types.UPDATE_SET_REPAIR_DATA](state, { value }) {
    state.setRepairData = value;
  },
  [types.UPDATE_DEV_DELI_OVERVIEW_DATA](state, { value }) {
    state.devDeliOverviewData = value;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
};

import * as types from './types';

const state = {
  isInIframe: false,
  lang: localStorage.getItem('PENGUIN-FE_LANGUAGE') || 'zh-CN',
};

const getters = {};

const actions = {
  updateIsInIframe({ commit }, value) {
    commit(types.UPDATE_IS_IN_IFRAME, { value });
  },
  changeLang({ commit }, value) {
    commit(types.CHANGE_LANG, { value });
  },
};

const mutations = {
  [types.UPDATE_IS_IN_IFRAME](state, { value }) {
    state.isInIframe = value;
  },
  [types.CHANGE_LANG](state, { value }) {
    state.lang = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
